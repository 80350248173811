.awg__spotlight {
    background-image: url(../../../assets/backgrounds/ant-war-background-mobile.jpg);
    background-size: cover;
    background-position: center;
    

    &-innercontainer {
        position: relative;
        max-height: 80vh;
        overflow: hidden;
        height: 0; // This prevents the collapse and sets a padding-bottom relative to the parent
        padding-bottom: 45%; // For 16:9 aspect ratio, adjust as needed
          
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 115%;
            object-fit: cover; /* This will cover the area, may clip the video, but maintains aspect ratio */
        }
    }

    &-foreground {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        display: flex;
        height: 100%;
    
        h2 {
            color: white;
            text-align: center;
            position: relative;
            top: -20px;
        }
    }

    &-wrap {
        display: flex;
        flex-direction: column;
        max-width: 90%;
        margin: auto;

        img {
            max-width: 100%;
            width: 850px;
        }
    }

    &-button {
        margin: auto;
        margin-top: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .awg__spotlight-wrap {
        img {
            width: 700px;
        }
    }

    .awg__spotlight-innercontainer {
        padding-bottom: 50%;
    }
}


@media screen and (max-width: 768px) {

    .awg__spotlight {
        height: 100vh;

        &-innercontainer {
            max-height: 100vh;
            height: 100%;
            video {
                display: none;
            }
        }

        &-foreground {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            height: 100%;
        
            h2 {
                color: white;
                text-align: center;
                position: relative;
                top: 0px;
            }
        }
    }
}
