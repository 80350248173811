/*--------------------------------------------------------------------*\
    ANIMATIONS
\*--------------------------------------------------------------------*/

@keyframes slide-animation-up {
    0% { opacity: 0;
          bottom: 0;} /* Starts at top of container, invisible */
    11% { opacity: 1;
          bottom: 0;} /* Fade in complete, move up. */
      /* */
    14% { opacity: 0; } /* Fade first div out over 3%*/
    16% { opacity: 0; }
    22% { bottom: 100%; } /* Second div arrives into position */
    26% { opacity: 1; }
    33% { bottom: 100%;
          opacity: 1;} /* Delay finished, start 3rd div */
    36% { opacity: 0; }
    38% { opacity: 0; }
    44% { bottom: 200%; } /* 3rd div reaches position */
    48% { opacity: 1; }
    55% { bottom: 200%;
          opacity: 1; } /* 3rd moves up, 4th coming */
    58% { opacity: 0; }
    60% { opacity: 0; }
    66% { bottom: 300%; } /* 4th arrives, delay start*/
    70% { opacity: 1; }
    77% { bottom: 300%;
          opacity: 1; } /* 4th moves up, 5th coming */
    80% { opacity: 0; }
    82% { opacity: 0; }
    88% { opacity: 1;
          bottom: 400%; } /* 5th arrives, begin fade out */
    100% { opacity: 0;
          bottom: 400%; } /* 5th faded out completely, instant back to div 1 */
  }

@keyframes slide-animation-down {
    0% { opacity: 0;
          bottom: 400%;}
    11% { opacity: 1;
          bottom: 400%;}
      /* */
    14% { opacity: 0; }
    16% { opacity: 0; }
    22% { bottom: 300%; }
    26% { opacity: 1; }
    33% { bottom: 300%;
          opacity: 1;}
    36% { opacity: 0; }
    38% { opacity: 0; }
    44% { bottom: 200%; }
    48% { opacity: 1; }
    55% { bottom: 200%;
          opacity: 1; }
    58% { opacity: 0; }
    60% { opacity: 0; }
    66% { bottom: 100%; }
    70% { opacity: 1; }
    77% { bottom: 100%;
          opacity: 1; } 
    80% { opacity: 0; }
    82% { opacity: 0; }
    88% { opacity: 1;
          bottom: 0; }
    100% { opacity: 0;
          bottom: 0; }
}
  
@keyframes grow-bounce1 {
    35% {
        transform: scale(1.2,1.2);
    }
    55% {
        transform: scale(1,1);
    }
    70% {
        transform: translate3d(0, 0, 0);
    }
    72% {
        transform: translate3d(2px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
  
@keyframes grow-bounce2 {
    40% {
        transform: scale(1.3,1.3);
    }
    60% {
        transform: scale(1,1);
    }
    70% {
        transform: translate3d(0, 0, 0);
    }
    74% {
        transform: translate3d(2px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
  
@keyframes grow-bounce3 {
    45% {
        transform: scale(1.4,1.4);
    }
    65% {
        transform: scale(1,1);
    }
    70% {
        transform: translate3d(0, 0, 0);
    }
    76% {
        transform: translate3d(2px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes grow-bounce4 {
    50% {
        transform: scale(1.5,1.5);
    }
    70% {
        transform: translate3d(0, 0, 0);
    }
    78% {
        transform: translate3d(2px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes grow-bounce5 {
    55% {
        transform: scale(1.6,1.6);
    }
    70% {
        transform: translate3d(0, 0, 0);
    }
    80% {
        transform: translate3d(2px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
  
.awg__endorsement {
    position: relative;

    &-masked-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        background-image: url('../../../assets/backgrounds/dirt-bg-short.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-mask-image: url('../../../assets/backgrounds/dirt-bg-short-mask.png');
        mask-image: url('../../../assets/backgrounds/dirt-bg-short-mask.png');
        -webkit-mask-mode: alpha;
        mask-mode: alpha;
        mask-size: 100% 100%;
        -webkit-mask-size: 100% 100%;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
    }

    h2 {
        color: var(--sand-white);
        text-transform: uppercase;
        text-align: center;
        font-size: 3.5rem;
        font-family: var(--font-main);
        margin-bottom: 2rem;
    }

    h3 {
        color: var(--sand-white);
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 1rem;
        text-align: center;
        font-size: 2.5rem;
        font-family: var(--font-main);
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    p {
        color: var(--sand-white);
        font-size: 1.6rem;
        font-family: var(--font-main);
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    &-innercontainer {
        position: relative;
        max-width: 1600px;
        margin: auto;
        padding: 24rem 0 5rem 0;
        z-index: 2;

    }

    &-sliders {
        display: flex;
    }

    &-review {
        padding: 5rem 1rem;
        width: 33%;
        border: 2px solid var(--sand-white);
        border-radius: 14px;
        margin: 1rem 0.75rem;
        background: linear-gradient(180deg, rgba(55,33,35,1) 4%, rgba(21,21,21,1) 96%);
        box-shadow: 2px 7px 25px rgba(0, 0, 0, 0.5);
    }

    &-stars {
        display: flex;
        justify-content: center;
        align-items: center;

        .glsr-star {
            width: 30px;
            height: 30px;
            background-size: 30px;
            background-image: url('../../../assets/star.png');
            margin: 10px;
        }
        .one-star {
            animation: grow-bounce1 4s cubic-bezier(.36,.07,.19,.97) both infinite;
        }
        .two-star {
            animation: grow-bounce2 4s cubic-bezier(.36,.07,.19,.97) both infinite;
        }
        .three-star {
            animation: grow-bounce3 4s cubic-bezier(.36,.07,.19,.97) both infinite;
        }
        .four-star {
            animation: grow-bounce4 4s cubic-bezier(.36,.07,.19,.97) both infinite;
        }
        .five-star {
            animation: grow-bounce5 4s cubic-bezier(.36,.07,.19,.97) both infinite;
        }
    }

    &-slider {
        width: 100%;
        height: 80px;
        overflow: hidden;
        margin: 0 auto;

        > div {
            position: relative;
            bottom: 0;
            right: 0;
            animation: slide-animation-down 20s infinite;

            div {
                padding: 4px 20px;
                width: 100%;
                height: 80px;
                position: relative;
                box-sizing: border-box;
            }
        }


    }
}

@media screen and (max-width: 1440px) {
    .awg__endorsement-review {
        width: 50%;
    }
    .awg__endorsement-review:nth-of-type(3) {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .awg__endorsement-review {
        width: 100%;
    }
    .awg__endorsement-review:nth-of-type(2) {
        display: none;
    }
}


@media screen and (max-width: 450px) {
    .awg__endorsement-review {
        width: 95%;
        margin: auto;
        padding: 2rem 1rem 1rem 1rem;
    }
    .awg__endorsement h2 {
        font-size: 1.5rem;
    }
    .awg__endorsement h3 {
        font-size: 2rem;
    }
    .awg__endorsement-stars .glsr-star {
        width: 20px;
        height: 20px;
        background-size: 20px;
    }

    .awg__endorsement-masked-bg {
        background-image: unset;
    }

    .awg__endorsement-innercontainer {
        padding: 7rem 0 5rem 0;
    }
}