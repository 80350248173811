.awg__historyad {
    background-image: url(../../../assets/about/ant-war-history-banner.jpg);
    background-size: cover;
    color: var(--sand-white);
    text-shadow: 0 0 5px rgba(0,0,0,1);
    min-height: 220px;
    display: flex;

    > div {
        background: rgba(0,0,0,.2);
        margin: 0.75rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            margin-top: 0;
        }
    }

    h3 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

}


@media screen and (max-width: 450px) {
    .awg__historyad {
        > div {
            padding: 0.5rem;
            margin: 0;
        }
    }
}