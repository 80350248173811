.awg__tutorial {
    background-image: url('../../../assets/backgrounds/dirt-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 3rem 1rem 8rem 1rem;
    -webkit-mask-image: url('../../../assets/backgrounds/dirt-bg-mask.png');
    mask-image: url('../../../assets/backgrounds/dirt-bg-mask.png');
    -webkit-mask-mode: alpha;
    mask-mode: alpha;
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    padding-top: 250px;

    &-innercontainer {
        max-width: 1800px;
        margin: auto;
    }
}

.awg__tutrow {
    position: relative;

    &-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        > div {
            position: relative;
            width: 50%;
            overflow: hidden;
        }
    }

    &-text {
        color: var(--sand-white);
        font-family: var(--font-main);
        flex: 1;
        overflow: hidden; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            font-size: 3.2rem;
            text-shadow: 2px 2px 4px rgb(53 121 17);
        }

        p {
            font-size: 1.6rem;
            margin: 2rem 0;
            text-shadow: 2px 2px 4px rgb(53 121 17);
        }

        span {
            color: yellow;
        }
    }

    &-image {
        flex: 1;
        overflow: hidden;
        -webkit-mask-image: url('../../../assets/tutorial/image-mask.png');
        mask-image: url('../../../assets/tutorial/image-mask.png');
        -webkit-mask-mode: alpha;
        mask-mode: alpha;
        mask-size: 100% 100%;
        -webkit-mask-size: 100% 100%;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;


        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}

.awg__tutrow:nth-of-type(even) {

    .awg__tutrow-container {
        flex-direction: row-reverse;
    }

}

@media screen and (max-width: 1440px) {
    .awg__tutrow {
        &-text {
            text-align: center;
            
            h2 {
                font-size: 2.4rem;
            }
    
            p {
                font-size: 1.4rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .awg__tutrow {
        &-container {
            flex-direction: column !important;

            > div {
                width: 100%;
            }
        }

        &-text {
            margin-top: 1rem;
            margin-bottom: 3rem;
        }
    }
}

@media screen and (max-width: 450px) {

    .awg__tutorial {
        padding-top: 100px;
        -webkit-mask-image: unset;
        mask-image: unset;
    }

    .awg__tutrow-text {
        h2 {
            font-size: 1.4rem;
        }
        p {
            margin: 1rem 0 2rem 0;
        }
    }
}
