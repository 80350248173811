.awg__navtop {
    background-color: var(--almost-black);
    position: sticky;
    top: 0;
    z-index: 3;

    &-innercontainer {
        margin: auto;
        padding: 10px 0;
        max-width: var(--site-width);
    }

    &dsk-links {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;

        &_container {
            display: flex;

            a {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                color: var(--sand-white);
                font-size: 17.5px;
                text-transform: uppercase;
                font-weight: 600;
                font-family: var(--font-main);

                &:hover {
                    color: white;
                }
            }

            .active {
                color: var(--vibrant-brown) !important;
            }
        }

        &_logo {
            display: flex;

            a {
                display: flex;
            }

            img {
                width: 150px;
                margin: auto;
            }
        }
    }

    &mob-menu {
        display: none;

        svg {
            margin: auto;
        }
    }

    &mob-menu_container-links {
        color: white;
        display: flex;
        flex-direction: column;

        a {
            color: var(--sand-white);
            font-size: 17.5px;
            text-transform: uppercase;
            font-weight: 600;
            font-family: var(--font-main);
            padding: 1rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .awg__navtop {
        &dsk-links {
            &_container {
                display: none;
            }
        }
        &mob-menu {
            display: flex;
        }
    }
}
