* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  background-color: #24211d;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: unset;
  text-decoration: none;
}