.awg__homepage-endorsementAndTutorial {
    background: linear-gradient(180deg, rgba(61,39,41,1) 2%, rgba(30,19,20,1) 10%);
    position: relative;
}


.awg__homepage-crossbar {
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    height: 100px;
    width: 100%;
    z-index: 1;
}


@media screen and (max-width: 450px) {
    .awg__homepage-endorsementAndTutorial {
        overflow: hidden;
    }
    
    .awg__homepage-crossbar {
        width: unset;
        left: -100px;
    }
}