$base-color: rgba(0,0,0,.5);
$shadow-light: rgba(0,0,0,.6);
$shadow-dark: rgba(0,0,0,.8);
$shadow-deep: rgba(0,0,0,.4);

.awg-button {
    position: relative;
    display: inline-block;
    background: $base-color;
    background-color: #24211d;
    background-origin: border-box;
    border: 1px solid grey;
    border-radius: 7px;
    padding: 12px 20px;
    color: #e9ded3;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-family: Eurostile Extd, Source Sans Pro, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;

    box-shadow: 
      0 2px 8px $shadow-light, 
      0 8px 32px $shadow-deep;

    text-shadow: 
      0 1px 2px $shadow-dark, 
      0 4px 8px $shadow-light;
}

@media screen and (max-width: 450px) {
  .awg-button {
    font-size: 12px;
  }
}
