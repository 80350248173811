.awg__footer {
    margin-top: auto;
    background-color: var(--almost-black);
    padding: 20px 10px 40px;

    &-links_logo {
        display: flex;

        img {
            width: 250px;
            margin: auto;
        }
    }

    &-links_container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        a {
            color: var(--sand-white);
            display: flex;
            align-items: center;
            padding: 10px 20px;
            font-size: 17.5px;
            text-transform: uppercase;
            font-weight: 600;
            font-family: var(--font-main);

            &:hover {
                color: white;
            }
        }
    }

    &-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--sand-white);
        font-family: var(--font-main);
        font-size: 13px;
        margin-top: 16px;
    }
}
